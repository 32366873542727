$(document).ready(function(){

    /*
     * Slick slider
     */
    $('.your-class').slick({
        dots: false,
        arrows: true,
        autoplay: false,
        slidesToShow: 4,
        infinite: true,
        slidesToScroll: 1,
        swipe: false,
        // customPaging: function(slider, i) {
        //     var title = $(slider.$slides[i]).data('title');
        //     return '<a class="slider-main__nav-btn" href="javascript:void(0);">'+title+'</a>';
        // },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    swipe: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    swipe: true,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });


    /*
     * Custom mobile nav
     */
    $(".icon-unfold").click(function(){

        $(this).toggleClass("icon-unfold--down");

        $(this).parents().closest('li').find('dropdown').addClass("open");

        var dropdown = $(this).next();

        dropdown.slideToggle();

    });


    /*
     * Fancybox
     */
    $(".fancybox").fancybox();


});